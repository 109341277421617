import Vue from 'vue'
import Router from 'vue-router'

let originPush = Router.prototype.push;  //备份原push方法

Router.prototype.push = function (location, resolve, reject) {
    if (resolve && reject) {    //如果传了回调函数，直接使用
        originPush.call(this, location, resolve, reject);
    } else {                     //如果没有传回调函数，手动添加
        originPush.call(this, location, () => {
        }, () => {
        });
    }
}

Vue.use(Router)

export default new Router({
    mode: "hash",
    routes: [
        {
            path: '/',
            name: 'Home',
            component: () => import("@/views/index/index.vue")
        }
    ]
})
