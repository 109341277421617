<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {

  }
}
</script>

<style lang="scss">
@import '@/assets/css/public.css';
@import '@/assets/css/theme.scss';
</style>
